import React, {FunctionComponent} from "react";

import "./main.scss";
import {isMobile} from "react-device-detect";
import { Buffer } from "buffer";
import Button from "../components/button";
import GravField from "../components/grav-field";
import Link from "../components/link";
import NavButton from "../components/nav-button";

const Main: FunctionComponent = ({...props}) => {
    return (
        <div className='main-wrapper'>
            <div className={`${!isMobile ? "main-container" : "main-container-m"}`}>
                <h1>jake postiglione</h1>


                {/*{isMobile && <p style={{fontStyle: "italic"}}>**best viewed in a desktop browser**</p>}*/}

                <p>new york, ny</p>
                <p>bs in applied computational physics from <Link link="https://www.citytech.cuny.edu/">nycct</Link></p>
                <p>physics phd student at the <Link link="https://www.gc.cuny.edu/">cuny gc</Link></p>
                <p>aspiring astrophysicist</p>

                <p>

                </p>

                <p>
                    find me on
                    <Button link={"https://bsky.app/profile/did:plc:uzecijkzuq4b7yjiwdg4b52i"}>bluesky</Button>,
                    <Button link={"https://linkedin.com/in/jake-postiglione"}>linkedin</Button>, and
                    <Button link={'https://github.com/jakepost'}>github</Button>
                </p>

                <p>
                    send me an
                    <Button link={`${Buffer.from('bWFpbHRvOg==', 'base64')}${Buffer.from(`anBvc3RpZ2xpb25lQGdyYW${Buffer.from('UmpaVzUwWlhJdVkzVnVlUzVsWkhVPQ==', 'base64')}`, 'base64')}`}>email</Button>
                    or checkout my
                    <NavButton to={'/cv'}>html cv</NavButton>
                </p>


                <GravField/>
            </div>
        </div>
    );
}

export default Main;