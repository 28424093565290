import React, {FunctionComponent} from "react";

import "./button.scss";

import useCursorHoverHandlers from "../hooks/useCursorHoverHandlers";

const Button: FunctionComponent<{link: string | undefined}> = ({...props}) => {
    return (
        <div onClick={() => {if (props.link) window.open(`${props.link}`, '_blank')}} className="button" {...useCursorHoverHandlers()}>
            <div className="content">
                {props.children}
            </div>
        </div>
    );
};

export default Button;