import React, {FunctionComponent, useEffect, useRef} from "react";
import {isMobile} from "react-device-detect";

const GravField: FunctionComponent<{notFound?: boolean | undefined}> = ({...props}) => {
    const canvasRef = useRef(undefined as any);
    const requestRef = useRef(undefined as any);
    const previousTimeRef = useRef();
    const isPaused = useRef(false)

    const gravityAnchorsRef = useRef(undefined as any);

    const h = 10E-5;

    const particlesRef = useRef(undefined as any);

    const clamp = (num: number, min: number, max: number) => Math.min(Math.max(num, min), max);

    let resizeTimeout: NodeJS.Timeout;

    function calcPotential(x:number, y: number, m: number): number {
        let pot = 0;

        const gravityAnchors = gravityAnchorsRef.current;

        gravityAnchors.forEach((gravityAnchor: {x: number, y: number, m: number, s: number} ) =>
        {
            let r = Math.sqrt(((gravityAnchor.x - x) ** 2) + ((gravityAnchor.y - y) ** 2));
            pot += (gravityAnchor.m * m) / r;
        })

        const particles = particlesRef.current;

        particles.forEach((particle: {x: number, y: number, vX: number, vY: number, m: number}) =>
        {
            if (x !== particle.x && y !== particle.y && m !== particle.m)
            {
                let r = Math.sqrt(((particle.x - x) ** 2) + ((particle.y - y) ** 2));

                pot += (particle.m * m) / r ** 2;
            }
        })

        return pot;
    }

    function partialX(x: number, y:number, m:number)
    {
        return (calcPotential(x + h / 2, y, m) - calcPotential(x - h / 2, y, m)) / h;
    }

    function partialY(x: number, y:number, m:number)
    {
        return (calcPotential(x, y + h / 2, m) - calcPotential(x, y - h / 2, m)) / h;
    }

    const animate = (time: any) => {
        if (previousTimeRef.current !== undefined && canvasRef.current !== undefined && particlesRef !== undefined) {
            const delta = (time - previousTimeRef.current) / 10000;

            const canvas = canvasRef.current;
            let context = canvas.getContext('2d');

            canvas.width = window.innerWidth;
            canvas.height = window.innerHeight;

            context.fillStyle = '#ffffff'

            if (props.notFound) context.font = 'normal 200 10px ballinger-mono'

            let gravityAnchors = gravityAnchorsRef.current;
            let particles = particlesRef.current;

            let maxSpeed = 2000;

            if (!isPaused.current) {
                particles.forEach((particle: { x: number, y: number, vX: number, vY: number, m: number, s: number }) => {

                    let gravity = {x: partialX(particle.x, particle.y, particle.m), y: partialY(particle.x, particle.y, particle.m)}
                    particle.vX += gravity.x;
                    particle.vY += gravity.y;

                    particle.vX = clamp(particle.vX, -maxSpeed, maxSpeed);
                    particle.vY = clamp(particle.vY, -maxSpeed, maxSpeed);

                    particle.x += particle.vX * delta;
                    particle.y += particle.vY * delta;

                    if (particle.y < 0) {
                        particle.y = canvas.height - (particle.s / 2);
                        particle.vX = -particle.vX;
                    }
                    if (particle.y > canvas.height) {
                        particle.y = (particle.s / 2);
                        particle.vX = -particle.vX;
                    }

                    if (particle.x < 0) {
                        particle.x = canvas.width - (particle.s / 2);
                        particle.vY = -particle.vY;
                    }

                    if (particle.x > canvas.width) {
                        particle.x = (particle.s / 2);
                        particle.vY = -particle.vY;
                    }
                })

                let particlesToRemove = [] as any;
                let newParticles = [] as any;

                particles.forEach((particle: { x: number, y: number, vX: number, vY: number, m: number, s: number }) => {
                    gravityAnchors.forEach((gravityAnchor: {x: number, y: number, m: number, s: number} ) =>
                    {
                        let r = Math.sqrt(((gravityAnchor.x - particle.x) ** 2) + ((gravityAnchor.y - particle.y) ** 2));

                        if (r < gravityAnchor.s / 2) {
                            particlesToRemove.push(particle);
                        }
                    })
                })

                particles.forEach((particleOne: { x: number, y: number, vX: number, vY: number, m: number, s: number }) => {
                    if (!particlesToRemove.includes(particleOne)) {
                        particles.forEach((particleTwo: { x: number, y: number, vX: number, vY: number, m: number, s: number }) => {
                            if (!particlesToRemove.includes(particleTwo) && particleTwo !== particleOne) {
                                let r = Math.sqrt(((particleOne.x - particleTwo.x) ** 2) + ((particleOne.y - particleTwo.y) ** 2));

                                if (r < particleOne.s / 2 || r < particleTwo.s / 2) {
                                    let x = particleOne.m >= particleTwo.m ? particleOne.x : particleTwo.x;
                                    let y = particleOne.m >= particleTwo.m ? particleOne.y : particleTwo.y;

                                    let vx = (particleOne.m * particleOne.vX + particleTwo.m * particleTwo.vX) / (particleOne.m + particleTwo.m)
                                    let vy = (particleOne.m * particleOne.vY + particleTwo.m * particleTwo.vY) / (particleOne.m + particleTwo.m)

                                    let newParticle = {x: x, y: y, vX: vx, vY: vy, m: particleOne.m + particleTwo.m, s: clamp((particleOne.s + particleTwo.s) / 1.5, 1, 50)};

                                    newParticles.push(newParticle);
                                    particlesToRemove.push(particleOne);
                                    particlesToRemove.push(particleTwo);
                                }
                            }
                        })
                    }
                })

                particlesToRemove.forEach((ptr: any) => {
                    particles = particles.filter((p: any) => p !== ptr)
                })

                newParticles.forEach((pta: any) => {
                    particles.push(pta)
                })
            }

            // let id = context.getImageData(0, 0, canvas.width, canvas.height);
            // let pixels = id.data;
            //
            // let mod = []
            // let div = 20
            // for (let pX = 0; pX < canvas.width; pX += div ) {
            //     let c = []
            //
            //     for (let pY = 0; pY < canvas.height; pY += div) {
            //         c.push(1 - calcPotential(pX, pY, 1) / 10)
            //     }
            //
            //     mod.push(c)
            // }
            //
            // for (let pX = 0; pX < canvas.width; pX += 1 )
            // {
            //     for (let pY = 0; pY < canvas.height; pY += 1 )
            //     {
            //         let off = (pY * id.width + pX) * 4;
            //         pixels[off] = 255 * mod[(pX - pX % div) / div][(pY - pY % div) / div];
            //         pixels[off + 1] = 255 * mod[(pX - pX % div) / div][(pY - pY % div) / div];
            //         pixels[off + 2] = 255 * mod[(pX - pX % div) / div][(pY - pY % div) / div];
            //         pixels[off + 3] = 255;
            //     }
            // }
            //
            // context.putImageData(id, 0, 0)

            if (!isMobile)
            {
                gravityAnchors.forEach((gravityAnchor: {x: number, y: number, m: number, s: number} ) =>
                {
                    context.fillRect(gravityAnchor.x - gravityAnchor.s / 2, gravityAnchor.y - gravityAnchor.s / 2, gravityAnchor.s, gravityAnchor.s);
                })
            }

            particles.forEach((particle: {x: number, y: number, vX: number, vY: number, m: number, s: number}) => {
                if (props.notFound)
                    context.fillText('404', particle.x - particle.s / 2, particle.y - particle.s / 2, particle.s * 10)
                else
                    context.fillRect(particle.x - particle.s / 2, particle.y - particle.s / 2, particle.s, particle.s)
            })

            particlesRef.current = particles;
        }

        if (particlesRef !== undefined && particlesRef.current.length < (isMobile ? 5 : 20))
        {
            generateRandomMap();
        }

        previousTimeRef.current = time;
        requestRef.current = requestAnimationFrame(animate);
    }

    function getRandomInt(max:number) {
        return Math.floor(Math.random() * max);
    }

    function getRandomArbitrary(min:number, max:number) {
        return Math.random() * (max - min) + min;
    }

    const onFocus = () => {
        console.log("Focused!");
        isPaused.current = false;
    };

    const onBlur = () => {
        console.log("Blurred!");
        isPaused.current = true;
    };

    const onResize = () => {
        cancelAnimationFrame(requestRef.current)

        clearTimeout(resizeTimeout);
        resizeTimeout = setTimeout(generateRandomMap, 40)
    }

    function generateRandomMap() {
        cancelAnimationFrame(requestRef.current)
        requestRef.current = requestAnimationFrame(animate);

        const canvas = canvasRef.current;

        canvas.width = window.innerWidth;
        canvas.height = window.innerHeight;

        let particles = [];
        let gravityAnchors = [];

        for (let x= 0; x <= getRandomInt((isMobile ? 2 : 3)); x ++)
        {
            let x = getRandomArbitrary(canvas.width * 0.1, canvas.width - (canvas.width * 0.1));
            let y = getRandomArbitrary(canvas.height * 0.7, canvas.height - (canvas.height * 0.1));
            let m = getRandomArbitrary(600, 900);
            let s = m / 20;

            gravityAnchors.push({x: x, y: y, m: m, s: s})
        }

        let dir= getRandomInt(100) <= 50 ? -1 : 1

        for(let i = 0; i < (isMobile ? 100 : 500); i++)
        {
            let x = getRandomInt(canvas.width);
            let y = getRandomInt(canvas.height)
            let m = getRandomArbitrary(10, 20)

            let vX = y < canvas.height / 2 ? getRandomArbitrary(0, 100 * dir) : getRandomArbitrary(0, -100 * dir)
            let vY = x < canvas.width / 2 ? getRandomArbitrary(0, -100 * dir) : getRandomArbitrary(0, 100 * dir)

            particles.push({x: x, y: y, vX: vX, vY: vY, m: m, s: m / 4});
        }

        gravityAnchorsRef.current = gravityAnchors
        particlesRef.current = particles;
    }

    useEffect(() => {
        generateRandomMap();

        requestRef.current = requestAnimationFrame(animate);

        window.addEventListener("focus", onFocus);
        window.addEventListener("blur", onBlur);
        window.addEventListener('resize', onResize)

        return () => {
            cancelAnimationFrame(requestRef.current)
            window.removeEventListener("focus", onFocus);
            window.removeEventListener("blur", onBlur);
            window.removeEventListener("resize", onResize);
        }
    }, []);

    return (
        <canvas ref={canvasRef} style={{position: "absolute", zIndex: -1, top: 0, left: 0}}/>
    );
};

export default GravField;