import React, {FunctionComponent} from "react";

import "./link.scss";

import Wave from '../assets/wave.svg';

import useCursorHoverHandlers from "../hooks/useCursorHoverHandlers";

const Link: FunctionComponent<{link: string | undefined}> = ({...props}) => {
    return (
        <div onClick={() => {if (props.link) window.open(`${props.link}`, '_blank')}} className="link" {...useCursorHoverHandlers()}>
            <div  className="content" style={{backgroundImage: `url(${Wave})`}}>
                {props.children}
            </div>
        </div>
    );
};

export default Link;