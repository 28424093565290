import React, {FunctionComponent} from "react";

import "./button.scss";

import useCursorHoverHandlers from "../hooks/useCursorHoverHandlers";
import {NavLink} from "react-router-dom";
import {To} from "react-router";

const NavButton: FunctionComponent<{to: To}> = ({...props}) => {
    return (
        <NavLink to={props.to} className="button" {...useCursorHoverHandlers()}>
            <div className="content">
                {props.children}
            </div>
        </NavLink>
    );
};

export default NavButton;