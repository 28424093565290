import React, {FunctionComponent, useCallback, useEffect, useState} from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter, Route, Routes, useLocation} from "react-router-dom";
import {CSSTransition, TransitionGroup} from "react-transition-group";

import './index.scss';

import Cursor, {CursorContext} from "./components/cursor"
import Main from "./pages/main";
import Scrollbar from "./components/scrollbar";
import CV from "./pages/cv";
import NotFound from "./pages/not-found";

const Container: FunctionComponent = ({...props}) => {
    const [cursor, setCursor] = useState({visible: false, active: false, hover: false, x: 0, y: 0});
    const [contentHeight, setContentHeight] = useState(1);
    let location = useLocation();

    const setCursorActive = useCallback((active: boolean) => {
        setCursor((prevState) => ({...prevState, active: active}));
    }, [setCursor]);

    const containerRef = React.useRef<HTMLDivElement>(null);

    useEffect(() => {
        const onResize = () => setContentHeight((containerRef.current?.clientHeight || 1));

        window.removeEventListener('resize', onResize);
        window.addEventListener('resize', onResize, {passive: true});

        // Short delay to wait for complete render before updating the content height.
        setTimeout(() => {
            onResize();
        }, 100)

        return () => window.removeEventListener('resize', onResize);
    }, [containerRef.current?.clientHeight]);

    return (
        <div ref={containerRef} className="container-wrapper"
             onMouseDown={() => {
                 setCursorActive(true)
             }}
             onMouseUp={() => {
                 setCursorActive(false)
             }}>
            <CursorContext.Provider value={{cursor, setCursor}}>
                <Cursor/>
                <Scrollbar contentHeight={contentHeight}/>
                <TransitionGroup component={null}>
                    <CSSTransition key={location.key} classNames="switch-fade" timeout={{appear: 0, enter: 300, exit: 0}}>
                        <Routes location={location}>
                            <Route caseSensitive path='/' element={<Main/>}/>
                            <Route caseSensitive path='/cv' element={<CV/>}/>
                            <Route path="/*" element={<NotFound/>}/>
                        </Routes>
                    </CSSTransition>
                </TransitionGroup>
            </CursorContext.Provider>
        </div>
    );
}

ReactDOM.render(
    <React.StrictMode>
        <BrowserRouter>
            <Container/>
        </BrowserRouter>
    </React.StrictMode>,
    document.getElementById('root')
);
