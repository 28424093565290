import React, {FunctionComponent} from "react";

import "./cv.scss";
import {isMobile} from "react-device-detect";
import NavButton from "../components/nav-button";

const CV: FunctionComponent = ({...props}) => {
    return (
        <div className="cv-wrapper">
            <div className={`${!isMobile ? "cv-container" : "cv-container-m"}`}>
                <NavButton to={'/'}>&lt; back</NavButton>
                <br/> <br/> <br/>
                Currently under construction, please check back soon!
            </div>
        </div>
    );
}

export default CV;