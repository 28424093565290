import React, {FunctionComponent} from "react";

import "./not-found.scss";
import {isMobile} from "react-device-detect";
import NavButton from "../components/nav-button";
import GravField from "../components/grav-field";

const NotFound: FunctionComponent = ({...props}) => {
    return (
        <div className="nf-wrapper">
            <div className={`${!isMobile ? "nf-container" : "nf-container-m"}`}>
                <NavButton to={'/'}>&lt; head back to safety</NavButton>

                <br/>
                <br/>

                <h1>error 404: page not found...</h1>
                {!isMobile && <GravField notFound={true}/>}
            </div>
        </div>
    );
}

export default NotFound;