import {useContext, useCallback} from "react";
import {CursorContext} from "../components/cursor";


function useCursorHoverHandlers() {
    const {setCursor} = useContext(CursorContext);

    const setCursorHover = useCallback((hover: boolean) => {
        setCursor((prevState) => ({...prevState, hover: hover}));
    }, [setCursor]);

    const onMouseEnter = useCallback(() => {
        setCursorHover(true);
    }, [setCursorHover]);

    const onMouseLeave = useCallback(() => {
        setCursorHover(false);
    }, [setCursorHover]);

    const onMouseUp = useCallback(() => {
        setCursorHover(false);
    }, [setCursorHover]);

    return {onMouseEnter, onMouseLeave, onMouseUp};
}

export default useCursorHoverHandlers;