import React, {createContext, Dispatch, FunctionComponent, SetStateAction, useContext, useEffect, useState} from "react";
import {isMobile} from "react-device-detect";

import "./cursor.scss";

import CursorPointer from '../assets/pointer.svg';
import CursorBracketLeft from '../assets/leftBracket.svg';
import CursorBracketRight from '../assets/rightBracket.svg';

interface ICursor {
    visible: boolean;
    hover: boolean;
    active: boolean;
    x: number;
    y: number;
}

interface ICursorContext {
    cursor: ICursor;
    setCursor: Dispatch<SetStateAction<ICursor>>;
}

export const CursorContext = createContext<ICursorContext>(undefined as any);

const Cursor: FunctionComponent = ({...props}) => {
    const {cursor, setCursor} = useContext(CursorContext);

    useEffect(() => {
        const updateCursorPosition = (event: any) => setCursor((prevState) => ({...prevState, x: event.clientX, y: event.clientY}));
        const handleMouseEnter = () => setCursor((prevState) => ({...prevState, visible: true, active: false}));
        const handleMouseLeave = () => setCursor((prevState) => ({...prevState, visible: false, active: false}));
        
        document.body.addEventListener("mouseenter", handleMouseEnter);
        document.body.addEventListener("mouseleave", handleMouseLeave);
        document.addEventListener("mousemove", updateCursorPosition, false);

        return () => {
            document.body.removeEventListener("mouseenter", handleMouseEnter);
            document.body.removeEventListener("mouseleave", handleMouseLeave);
            document.removeEventListener("mousemove", updateCursorPosition);
        };
    }, [setCursor]);

    if (isMobile) return null;

    return (
        <div className="cursor-container">
            <div className="cursor" style={{left: cursor.x, top: cursor.y, opacity: cursor.visible ? 100 : 0}}>
                <img className="pointer" src={CursorPointer} alt="Cursor Pointer"/>

                <div className="brackets" style={{transform: `rotate(${cursor.hover || cursor.active ? '90deg' : 0}`}}>
                    <img className="bracket" src={CursorBracketLeft} alt="Cursor Bracket" style={{transform: `translate(${cursor.active ? '5px': 0}, 0)`}} />
                    <img className="bracket" src={CursorBracketRight}  alt="Cursor Bracket" style={{transform: `translate(${cursor.active ? '-5px': 0}, 0)`}}/>
                </div>
            </div>
        </div>
    );
}

export default Cursor;